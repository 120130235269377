import * as React from "react"
import { Helmet } from "react-helmet"

import Theme from "../components/theme"

// import hero from '../images/header.jpg';

import '../styles/index.css';
import Footer from "../components/footer";
import Kontakt from "../components/kontakt";
import CookieConsent from "../components/cookieConsent";
import Header from "../components/header";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Zukunftsgespräch buchen</title>
        <meta name="description" content="Zukunftsgespräch buchen" />
        <meta name="keywords" content="supervision, freiburg, schweiz, deutschland, beratung, frauen, online" />
      </Helmet>

      <div className="p-10">
        <Header />
      </div>

      <Kontakt />

      <Footer />
      <CookieConsent />
    </Theme>
  )
}

export default IndexPage
