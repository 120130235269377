import React, { useEffect } from "react";
import { Mail, MapPin, Phone, Smartphone } from "react-feather";
import { trackEvent } from "../analytics";

const Kontakt = () => {
  useEffect(() => {
    const messageListener = (e) => {
      if (e.data.event && e.data.event.indexOf("calendly") === 0) {
        trackEvent("kalendar schritt", { step: e.data.event });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "calendly",
          calendlyType: e.data.event.replace("calendly.", ""),
        });
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    };
  });

  return (
    <div className="w-full relative" id="kontakt">
      <h3 className="text-2xl mb-4 font-semibold text-center">
        Kontaktieren Sie mich
      </h3>
      <div className="max-w-3xl md:max-w-full text-center relative z-20 mx-auto py-6 md:grid grid-cols-2">
        <div className="mb-8 md:mb-0">
          <div className="flex gap-2 justify-center items-center flex-row mb-3 text-lg">
            <MapPin size={20} />
            <div>
              Salzstraße 25
              <br />
              79098 Freiburg
            </div>
          </div>

          <a
            href="mailto:mail@angelahollstein.de"
            className="flex justify-center flex-row underline mb-3 text-lg"
            onClick={() => {
              trackEvent("email klick");
              return true;
            }}
          >
            <Mail className="mr-3" /> mail@angelahollstein.de
          </a>

          <a
            href="tel:076644033617"
            className="flex justify-center flex-row underline text-lg"
            onClick={() => {
              trackEvent("telefon klick");
              return true;
            }}
          >
            <Phone className="mr-3" /> 07664/4033617
          </a>
          <a
            href="tel:015738984206"
            className="flex justify-center flex-row underline text-lg"
            onClick={() => {
              trackEvent("telefon klick");
              return true;
            }}
          >
            <Smartphone className="mr-3" /> 01573/8984206
          </a>

          <form
            className="w-full max-w-xs mx-auto mt-8"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
            action="/kontakt-gesendet"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="text"
              name="name"
              className="h-12 py-2 px-4 w-full mb-3"
              placeholder="Vor- und Nachname"
              required
            />
            <input
              type="email"
              name="mail"
              className="h-12 py-2 px-4 w-full mb-3"
              placeholder="E-Mail Adresse"
              required
            />
            <input
              type="tel"
              name="telefon"
              className="h-12 py-2 px-4 w-full mb-3"
              placeholder="Telefonnummer (optional)"
            />
            <textarea
              className="h-32 py-2 px-4 w-full mb-3"
              placeholder="Ihre Nachricht"
              required
              name="text"
            />
            <button className="h-12 py-2 px-4 bg-gray-900 text-white text-sm w-full">
              Senden
            </button>
          </form>
        </div>

        <div>
          <p className="text-center text-gray-600 mb-5 md:hidden">
            Sie können mich auch gerne zur individuellen Terminvereinbarung
            persönlich kontaktieren
          </p>

          <div
            className="rounded-md p-3 md:p-0"
            style={{ height: 900, width: "100%" }}
            ref={(el) => {
              if (el && !el.querySelector("iframe")) {
                const setupCalendly = () => {
                  window.Calendly.initInlineWidget({
                    url: "https://calendly.com/angela-hollstein/onlinecoachingfuerfrauen?background_color=fff4f6&primary_color=60063c",
                    parentElement: el,
                  });
                };
                if (window.Calendly) {
                  setupCalendly();
                } else {
                  const checkInterval = setInterval(() => {
                    if (window.Calendly) {
                      setupCalendly();
                      clearInterval(checkInterval);
                    }
                  }, 100);
                }
              }
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
